import {
  format,
  formatISO,
  formatRelative as fnsFormatRelative,
  getHours,
  getMinutes,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
} from "date-fns";
import { nb } from "date-fns/locale";

export function formatDate(date: Date | string) {
  return new Date(date).toLocaleString("no-NO", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
}

export function formatDateTime(date: Date | string) {
  return new Date(date).toLocaleString("no-NO", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function formatDateShort(inputDate: Date | string) {
  const date = inputDate instanceof Date ? inputDate : new Date(inputDate);
  return format(date, "dd.MM.yy", { locale: nb });
}

export function formatRelative(inputDate: Date | string) {
  const date = inputDate instanceof Date ? inputDate : new Date(inputDate);
  return fnsFormatRelative(date, new Date(), { locale: nb });
}

export function formatLocalizedDate(inputDate: Date | string) {
  const date = inputDate instanceof Date ? inputDate : new Date(inputDate);
  return format(date, "PP", { locale: nb });
}

export function formatLocalizedDateP(inputDate: Date | string) {
  const date = inputDate instanceof Date ? inputDate : new Date(inputDate);
  return format(date, "P", { locale: nb });
}

export function formatLocalizedDateAndTime(inputDate: Date | string) {
  const date = inputDate instanceof Date ? inputDate : new Date(inputDate);
  return format(date, "Pp", { locale: nb });
}

export function formatLocalizedTime(inputDate: Date | string) {
  const date = inputDate instanceof Date ? inputDate : new Date(inputDate);
  return format(date, "p", { locale: nb });
}

export function setTimeOfDayByHourMinute(hour?: number | null, minute?: number | null) {
  if ((hour || hour === 0) && (minute || minute === 0)) {
    const formatHour = hour.toString().padStart(2, "0");
    const formatMinute = minute.toString().padStart(2, "0");
    return `${formatHour}:${formatMinute}`;
  }
  return "";
}

// TODO better name
export function getTimeOfDay(date: Date) {
  const hours = getHours(date).toString().length === 2 ? getHours(date) : `0${getHours(date)}`;
  const minutes = getMinutes(date).toString().length === 2 ? getMinutes(date) : `${getMinutes(date)}0`;
  return `${hours}:${minutes}`;
}

// TODO merge with setDateHourAndMin?
export function setTimeOfDay(hourInput: string, date: Date) {
  let dateObject = date instanceof Date ? date : new Date(date);
  const [hour, minute] = hourInput.split(":");
  dateObject = setHours(dateObject, +hour);
  dateObject = setMinutes(dateObject, +minute);

  return dateObject;
}

// assuming sec and ms to 0
export const setDateHourAndMin = (date: Date, hourAndMin: string): Date => {
  const [hours, minutes] = hourAndMin.split(":");
  const dateHourMin = setMinutes(setHours(date, +hours), +minutes);
  return setMilliseconds(setSeconds(dateHourMin, 0), 0);
};

/** Only date (first part) */
export const getIsoDate = (date: Date | string): string => {
  if (typeof date === "string") {
    date = new Date(date);
  }
  return formatISO(date, { representation: "date" });
};

/** Return hour/minute numbers formatted as HH:mm */
export const formatHourMinutes = (hour: number, minute: number) => {
  const hourPadded = (hour ?? 0).toString().padStart(2, "0");
  const minutePadded = (minute ?? 0).toString().padStart(2, "0");
  return `${hourPadded}:${minutePadded}`;
};

export const getFormattedDatePeriod = (startDate: string, endDate: string) =>
  `${formatDate(startDate)}–${formatDate(endDate)}`;
