














import BaseSheet from "@/components/shared/sheet/BaseSheet.vue";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { useRestrictedAccessApi } from "@/shared/helpers/accessLevelApiAdapter";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";

export default defineComponent({
  name: "CourseHeader",
  components: { BaseSheet },
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore<StoreState>();
    const route = useRoute();
    const restrictedAccessApi = useRestrictedAccessApi();
    const headerInformation = ref<{ title: string; value: string | number }[]>();
    const localIsVocationalSchool = computed(() => isVocationalSchool(store.state.plans.studyplan.mainCourseId));

    const getCurrentAddress = async () => {
      if (Object.keys(props.course.courseLocation).length === 0 || !props.course.courseLocation.areaId) {
        openNotification(store, NotificationItemType.Error, "Dette kurset mangler sted (areaId)");
      }
      return (await restrictedAccessApi.getAreaById(props.course.courseLocation.areaId)).data.place || "Fant ikke sted";
    };

    onMounted(async () => {
      globalLoadingWrapper({ blocking: false }, async () => {
        const address = await getCurrentAddress();

        headerInformation.value = [
          { title: localIsVocationalSchool ? "ID" : "Kurs-ID", value: route.params.id },
          { title: localIsVocationalSchool ? "Utdanning" : "Kursnavn", value: props.course.courseName },
          { title: localIsVocationalSchool ? "Undervisningslokale" : "Sted", value: address },
          { title: "Status", value: props.course.status },
        ];
      });
    });

    return {
      headerInformation,
      navigateBack: () => useNavigateBack(SingleCourseRouteNames.CourseDashboard),
    };
  },
});
