




































import { api } from "@/api/api";
import CourseAddNoteModal from "@/components/course/details/CourseAddNoteModal.vue";
import CourseHeader from "@/components/course/details/CourseHeader.vue";
import BaseExpansionPanelList from "@/components/shared/BaseExpansionPanelList.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import BaseSheet from "@/components/shared/sheet/BaseSheet.vue";
import BaseSheetHeader from "@/components/shared/sheet/BaseSheetHeader.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { ExpansionPanelElement } from "@/shared/interfaces/ExpansionPanelElement.interface";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import { formatRelative } from "@/shared/helpers/dateHelpers";

export default defineComponent({
  name: "CourseNotesPage",
  components: {
    BaseExpansionPanelList,
    BaseTooltipIconButton,
    CourseAddNoteModal,
    BaseModal,
    CourseHeader,
    BaseSheet,
    BaseSheetHeader,
  },
  setup() {
    const store = useStore<StoreState>();
    const route = useRoute();
    const notes = ref<ExpansionPanelElement[]>([]);
    const modalData = ref(getInitialModalData());
    const currentEmployeeId = ref<number>();

    const removeNote = async (id: number) => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        await api.course.deleteCourseNoteAsync(+route.params.id, id);
        openNotification(store, NotificationItemType.Success, "Notat slettet");
      });
      loadNotes();
    };

    const loadNotes = async () => {
      notes.value = (await api.course.getCourseNotesAsync(+route.params.id)).data.map((current) => ({
        header: current.title ?? "Tittel mangler",
        content: current.text ?? "Innhold mangler",
        id: current.id,
        insertedBy: current.insertedByUserFullName ?? current.insertedBy,
        inserted: formatRelative(current.inserted),
        isRemoveable: currentEmployeeId.value === current.insertedBy,
      }));
    };

    const loadEmployeeSelf = async () => {
      currentEmployeeId.value = (await api.user.getCurrentEmployeeAsync()).data?.id;
    };

    const openAddNoteModal = useOpenModal(ModalType.Add, "notat", modalData);

    onMounted(async () => {
      await globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        await loadEmployeeSelf();
        await loadNotes();
      });
    });

    return {
      notes,
      removeNote,
      loadNotes,
      openAddNoteModal,
      modalData,
      course: computed(() => store.state.courses.course),
    };
  },
});
