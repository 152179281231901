






















import { api } from "@/api/api";
import { ApiUpsertCourseNoteDto } from "@/api/generated/Api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, PropType, ref } from "@vue/composition-api";

export default defineComponent({
  name: "CourseAddNoteModal",
  components: { BaseModalForm },
  emits: ["close", "reloadData"],
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      validator: (value: string) => (Object.values(ModalType) as string[]).includes(value),
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit, refs }) {
    const store = useStore<StoreState>();
    const route = useRoute();
    const noteModel = ref<ApiUpsertCourseNoteDto>({
      title: "",
      text: "",
    });

    const handleSubmit = () => {
      const isValid = getValidatableRef(refs.connectForm)?.validate();
      if (!isValid) {
        return;
      }
      if (props.modalType === ModalType.Add) {
        addNewNote();
      }
    };

    const addNewNote = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await api.course.createCourseNoteAsync(+route.params.id, noteModel.value);
        openNotification(store, NotificationItemType.Success, "Notat opprettet");
        emit("reloadData");
        emit("close");
      });
    };

    return {
      noteModel,
      validateNotEmpty,
      handleSubmit,
    };
  },
});
